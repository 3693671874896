@charset "UTF-8";
/**
 * @package     Joomla.Site
 * @subpackage  Templates.noName
 *
 * @copyright   Copyright (C) 2020 noName. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/**
 * @package     Joomla.Site
 * @subpackage  Templates.noName
 *
 * @copyright   Copyright (C) 2020 noName. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import 'wowjs/css/libs/animate.css';
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #c81e37;
  --bs-orange: #ea630f;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #929292;
  --bs-gray-dark: #666666;
  --bs-gray-100: #f3f3f3;
  --bs-gray-200: #d0d0cf;
  --bs-gray-300: #e2e2e2;
  --bs-gray-400: #808080;
  --bs-gray-500: #777777;
  --bs-gray-600: #929292;
  --bs-gray-700: #888888;
  --bs-gray-800: #666666;
  --bs-gray-900: #4d4d4d;
  --bs-primary: #f78b33;
  --bs-secondary: #741b1d;
  --bs-light: #f5eae1;
  --bs-success: #3ea06b;
  --bs-gray-100: #f3f3f3;
  --bs-gray-200: #d0d0cf;
  --bs-gray-300: #e2e2e2;
  --bs-gray-400: #808080;
  --bs-gray-500: #777777;
  --bs-gray-700: #888888;
  --bs-gray-600: #929292;
  --bs-gray-800: #666666;
  --bs-gray-900: #4d4d4d;
  --bs-body: #333333;
  --bs-info: #245692;
  --bs-warning: #ffc600;
  --bs-danger: #c81e37;
  --bs-dark: #cccccc;
  --bs-black: #000000;
  --bs-red: #c81e37;
  --bs-orange: #ea630f;
  --bs-white: #ffffff;
  --bs-primary-rgb: 247, 139, 51;
  --bs-secondary-rgb: 116, 27, 29;
  --bs-light-rgb: 245, 234, 225;
  --bs-success-rgb: 62, 160, 107;
  --bs-gray-100-rgb: 243, 243, 243;
  --bs-gray-200-rgb: 208, 208, 207;
  --bs-gray-300-rgb: 226, 226, 226;
  --bs-gray-400-rgb: 128, 128, 128;
  --bs-gray-500-rgb: 119, 119, 119;
  --bs-gray-700-rgb: 136, 136, 136;
  --bs-gray-600-rgb: 146, 146, 146;
  --bs-gray-800-rgb: 102, 102, 102;
  --bs-gray-900-rgb: 77, 77, 77;
  --bs-body-rgb: 51, 51, 51;
  --bs-info-rgb: 36, 86, 146;
  --bs-warning-rgb: 255, 198, 0;
  --bs-danger-rgb: 200, 30, 55;
  --bs-dark-rgb: 204, 204, 204;
  --bs-black-rgb: 0, 0, 0;
  --bs-red-rgb: 200, 30, 55;
  --bs-orange-rgb: 234, 99, 15;
  --bs-white-rgb: 255, 255, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 51, 51, 51;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "SairaCondensed-Regular", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #333333;
  --bs-body-bg: #ffffff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d6d6d6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #f78b33;
  --bs-link-hover-color: #c66f29;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 0.6667rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.33335rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}

h3 {
  font-size: calc(1.2875rem + 0.45vw);
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #929292;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0.9375rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 2.133rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 0.33335rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 0.33335rem;
}

.g-3px,
.gx-3px {
  --bs-gutter-x: 3px;
}

.g-3px,
.gy-3px {
  --bs-gutter-y: 3px;
}

.g-15px,
.gx-15px {
  --bs-gutter-x: 15px;
}

.g-15px,
.gy-15px {
  --bs-gutter-y: 15px;
}

.g-25px,
.gx-25px {
  --bs-gutter-x: 25px;
}

.g-25px,
.gy-25px {
  --bs-gutter-y: 25px;
}

.g-35px,
.gx-35px {
  --bs-gutter-x: 35px;
}

.g-35px,
.gy-35px {
  --bs-gutter-y: 35px;
}

.g-700,
.gx-700 {
  --bs-gutter-x: 700px;
}

.g-700,
.gy-700 {
  --bs-gutter-y: 700px;
}

.g-n15,
.gx-n15 {
  --bs-gutter-x: -15px;
}

.g-n15,
.gy-n15 {
  --bs-gutter-y: -15px;
}

.g-n1,
.gx-n1 {
  --bs-gutter-x: -10px;
}

.g-n1,
.gy-n1 {
  --bs-gutter-y: -10px;
}

.g-n5,
.gx-n5 {
  --bs-gutter-x: -5px;
}

.g-n5,
.gy-n5 {
  --bs-gutter-y: -5px;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.6667rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.6667rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1.3334rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1.3334rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 2.0001rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 2.0001rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2.6668rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2.6668rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3.3335rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3.3335rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 4.0002rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 4.0002rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 4.6669rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 4.6669rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 5.3336rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 5.3336rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 6.0003rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 6.0003rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 6.667rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 6.667rem;
}

.g-gutter,
.gx-gutter {
  --bs-gutter-x: 2.133rem;
}

.g-gutter,
.gy-gutter {
  --bs-gutter-y: 2.133rem;
}

.g-out-container,
.gx-out-container {
  --bs-gutter-x: calc(50% - 50vw);
}

.g-out-container,
.gy-out-container {
  --bs-gutter-y: calc(50% - 50vw);
}
.font-sans-serif {
  font-family: var(--bs-font-sans-serif) !important;
}

.font-sans-serif-medium {
  font-family: var(--bs-font-sans-serif-medium) !important;
}

.font-sans-serif-semiBold {
  font-family: var(--bs-font-sans-serif-semiBold) !important;
}

.font-sans-serif-bold {
  font-family: var(--bs-font-sans-serif-bold) !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semi {
  font-weight: 600 !important;
}

.bg-primary {
  background-color: #f78b33 !important;
}

.bg-secondary {
  background-color: #741b1d !important;
}

.bg-light {
  background-color: #f5eae1 !important;
}

.bg-success {
  background-color: #3ea06b !important;
}

.bg-gray-100 {
  background-color: #f3f3f3 !important;
}

.bg-gray-200 {
  background-color: #d0d0cf !important;
}

.bg-gray-300 {
  background-color: #e2e2e2 !important;
}

.bg-gray-400 {
  background-color: #808080 !important;
}

.bg-gray-500 {
  background-color: #777777 !important;
}

.bg-gray-700 {
  background-color: #888888 !important;
}

.bg-gray-600 {
  background-color: #929292 !important;
}

.bg-gray-800 {
  background-color: #666666 !important;
}

.bg-gray-900 {
  background-color: #4d4d4d !important;
}

.bg-body {
  background-color: #333333 !important;
}

.bg-info {
  background-color: #245692 !important;
}

.bg-warning {
  background-color: #ffc600 !important;
}

.bg-danger {
  background-color: #c81e37 !important;
}

.bg-dark {
  background-color: #cccccc !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-red {
  background-color: #c81e37 !important;
}

.bg-orange {
  background-color: #ea630f !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.text-primary {
  color: #f78b33 !important;
}

.text-secondary {
  color: #741b1d !important;
}

.text-light {
  color: #f5eae1 !important;
}

.text-success {
  color: #3ea06b !important;
}

.text-gray-100 {
  color: #f3f3f3 !important;
}

.text-gray-200 {
  color: #d0d0cf !important;
}

.text-gray-300 {
  color: #e2e2e2 !important;
}

.text-gray-400 {
  color: #808080 !important;
}

.text-gray-500 {
  color: #777777 !important;
}

.text-gray-700 {
  color: #888888 !important;
}

.text-gray-600 {
  color: #929292 !important;
}

.text-gray-800 {
  color: #666666 !important;
}

.text-gray-900 {
  color: #4d4d4d !important;
}

.text-body {
  color: #333333 !important;
}

.text-info {
  color: #245692 !important;
}

.text-warning {
  color: #ffc600 !important;
}

.text-danger {
  color: #c81e37 !important;
}

.text-dark {
  color: #cccccc !important;
}

.text-black {
  color: #000000 !important;
}

.text-red {
  color: #c81e37 !important;
}

.text-orange {
  color: #ea630f !important;
}

.text-white {
  color: #ffffff !important;
}

.m-sm {
  margin: 0.33335rem !important;
}

.m-3px {
  margin: 3px !important;
}

.m-15px {
  margin: 15px !important;
}

.m-25px {
  margin: 25px !important;
}

.m-35px {
  margin: 35px !important;
}

.m-700 {
  margin: 700px !important;
}

.m-n15 {
  margin: -15px !important;
}

.m-n1 {
  margin: -10px !important;
}

.m-n5 {
  margin: -5px !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.6667rem !important;
}

.m-2 {
  margin: 1.3334rem !important;
}

.m-3 {
  margin: 2.0001rem !important;
}

.m-4 {
  margin: 2.6668rem !important;
}

.m-5 {
  margin: 3.3335rem !important;
}

.m-6 {
  margin: 4.0002rem !important;
}

.m-7 {
  margin: 4.6669rem !important;
}

.m-8 {
  margin: 5.3336rem !important;
}

.m-9 {
  margin: 6.0003rem !important;
}

.m-10 {
  margin: 6.667rem !important;
}

.m-gutter {
  margin: 2.133rem !important;
}

.m-out-container {
  margin: calc(50% - 50vw) !important;
}

.m-auto {
  margin: auto !important;
}

.mx-sm {
  margin-right: 0.33335rem !important;
  margin-left: 0.33335rem !important;
}

.mx-3px {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.mx-15px {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mx-25px {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.mx-35px {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.mx-700 {
  margin-right: 700px !important;
  margin-left: 700px !important;
}

.mx-n15 {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.mx-n1 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.mx-n5 {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.6667rem !important;
  margin-left: 0.6667rem !important;
}

.mx-2 {
  margin-right: 1.3334rem !important;
  margin-left: 1.3334rem !important;
}

.mx-3 {
  margin-right: 2.0001rem !important;
  margin-left: 2.0001rem !important;
}

.mx-4 {
  margin-right: 2.6668rem !important;
  margin-left: 2.6668rem !important;
}

.mx-5 {
  margin-right: 3.3335rem !important;
  margin-left: 3.3335rem !important;
}

.mx-6 {
  margin-right: 4.0002rem !important;
  margin-left: 4.0002rem !important;
}

.mx-7 {
  margin-right: 4.6669rem !important;
  margin-left: 4.6669rem !important;
}

.mx-8 {
  margin-right: 5.3336rem !important;
  margin-left: 5.3336rem !important;
}

.mx-9 {
  margin-right: 6.0003rem !important;
  margin-left: 6.0003rem !important;
}

.mx-10 {
  margin-right: 6.667rem !important;
  margin-left: 6.667rem !important;
}

.mx-gutter {
  margin-right: 2.133rem !important;
  margin-left: 2.133rem !important;
}

.mx-out-container {
  margin-right: calc(50% - 50vw) !important;
  margin-left: calc(50% - 50vw) !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-sm {
  margin-top: 0.33335rem !important;
  margin-bottom: 0.33335rem !important;
}

.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-15px {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-25px {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-35px {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-700 {
  margin-top: 700px !important;
  margin-bottom: 700px !important;
}

.my-n15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.my-n1 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.my-n5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.6667rem !important;
  margin-bottom: 0.6667rem !important;
}

.my-2 {
  margin-top: 1.3334rem !important;
  margin-bottom: 1.3334rem !important;
}

.my-3 {
  margin-top: 2.0001rem !important;
  margin-bottom: 2.0001rem !important;
}

.my-4 {
  margin-top: 2.6668rem !important;
  margin-bottom: 2.6668rem !important;
}

.my-5 {
  margin-top: 3.3335rem !important;
  margin-bottom: 3.3335rem !important;
}

.my-6 {
  margin-top: 4.0002rem !important;
  margin-bottom: 4.0002rem !important;
}

.my-7 {
  margin-top: 4.6669rem !important;
  margin-bottom: 4.6669rem !important;
}

.my-8 {
  margin-top: 5.3336rem !important;
  margin-bottom: 5.3336rem !important;
}

.my-9 {
  margin-top: 6.0003rem !important;
  margin-bottom: 6.0003rem !important;
}

.my-10 {
  margin-top: 6.667rem !important;
  margin-bottom: 6.667rem !important;
}

.my-gutter {
  margin-top: 2.133rem !important;
  margin-bottom: 2.133rem !important;
}

.my-out-container {
  margin-top: calc(50% - 50vw) !important;
  margin-bottom: calc(50% - 50vw) !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-sm {
  margin-top: 0.33335rem !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mt-700 {
  margin-top: 700px !important;
}

.mt-n15 {
  margin-top: -15px !important;
}

.mt-n1 {
  margin-top: -10px !important;
}

.mt-n5 {
  margin-top: -5px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.6667rem !important;
}

.mt-2 {
  margin-top: 1.3334rem !important;
}

.mt-3 {
  margin-top: 2.0001rem !important;
}

.mt-4 {
  margin-top: 2.6668rem !important;
}

.mt-5 {
  margin-top: 3.3335rem !important;
}

.mt-6 {
  margin-top: 4.0002rem !important;
}

.mt-7 {
  margin-top: 4.6669rem !important;
}

.mt-8 {
  margin-top: 5.3336rem !important;
}

.mt-9 {
  margin-top: 6.0003rem !important;
}

.mt-10 {
  margin-top: 6.667rem !important;
}

.mt-gutter {
  margin-top: 2.133rem !important;
}

.mt-out-container {
  margin-top: calc(50% - 50vw) !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-sm {
  margin-right: 0.33335rem !important;
}

.me-3px {
  margin-right: 3px !important;
}

.me-15px {
  margin-right: 15px !important;
}

.me-25px {
  margin-right: 25px !important;
}

.me-35px {
  margin-right: 35px !important;
}

.me-700 {
  margin-right: 700px !important;
}

.me-n15 {
  margin-right: -15px !important;
}

.me-n1 {
  margin-right: -10px !important;
}

.me-n5 {
  margin-right: -5px !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.6667rem !important;
}

.me-2 {
  margin-right: 1.3334rem !important;
}

.me-3 {
  margin-right: 2.0001rem !important;
}

.me-4 {
  margin-right: 2.6668rem !important;
}

.me-5 {
  margin-right: 3.3335rem !important;
}

.me-6 {
  margin-right: 4.0002rem !important;
}

.me-7 {
  margin-right: 4.6669rem !important;
}

.me-8 {
  margin-right: 5.3336rem !important;
}

.me-9 {
  margin-right: 6.0003rem !important;
}

.me-10 {
  margin-right: 6.667rem !important;
}

.me-gutter {
  margin-right: 2.133rem !important;
}

.me-out-container {
  margin-right: calc(50% - 50vw) !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-sm {
  margin-bottom: 0.33335rem !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-700 {
  margin-bottom: 700px !important;
}

.mb-n15 {
  margin-bottom: -15px !important;
}

.mb-n1 {
  margin-bottom: -10px !important;
}

.mb-n5 {
  margin-bottom: -5px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.6667rem !important;
}

.mb-2 {
  margin-bottom: 1.3334rem !important;
}

.mb-3 {
  margin-bottom: 2.0001rem !important;
}

.mb-4 {
  margin-bottom: 2.6668rem !important;
}

.mb-5 {
  margin-bottom: 3.3335rem !important;
}

.mb-6 {
  margin-bottom: 4.0002rem !important;
}

.mb-7 {
  margin-bottom: 4.6669rem !important;
}

.mb-8 {
  margin-bottom: 5.3336rem !important;
}

.mb-9 {
  margin-bottom: 6.0003rem !important;
}

.mb-10 {
  margin-bottom: 6.667rem !important;
}

.mb-gutter {
  margin-bottom: 2.133rem !important;
}

.mb-out-container {
  margin-bottom: calc(50% - 50vw) !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-sm {
  margin-left: 0.33335rem !important;
}

.ms-3px {
  margin-left: 3px !important;
}

.ms-15px {
  margin-left: 15px !important;
}

.ms-25px {
  margin-left: 25px !important;
}

.ms-35px {
  margin-left: 35px !important;
}

.ms-700 {
  margin-left: 700px !important;
}

.ms-n15 {
  margin-left: -15px !important;
}

.ms-n1 {
  margin-left: -10px !important;
}

.ms-n5 {
  margin-left: -5px !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.6667rem !important;
}

.ms-2 {
  margin-left: 1.3334rem !important;
}

.ms-3 {
  margin-left: 2.0001rem !important;
}

.ms-4 {
  margin-left: 2.6668rem !important;
}

.ms-5 {
  margin-left: 3.3335rem !important;
}

.ms-6 {
  margin-left: 4.0002rem !important;
}

.ms-7 {
  margin-left: 4.6669rem !important;
}

.ms-8 {
  margin-left: 5.3336rem !important;
}

.ms-9 {
  margin-left: 6.0003rem !important;
}

.ms-10 {
  margin-left: 6.667rem !important;
}

.ms-gutter {
  margin-left: 2.133rem !important;
}

.ms-out-container {
  margin-left: calc(50% - 50vw) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-sm {
  padding: 0.33335rem !important;
}

.p-3px {
  padding: 3px !important;
}

.p-15px {
  padding: 15px !important;
}

.p-25px {
  padding: 25px !important;
}

.p-35px {
  padding: 35px !important;
}

.p-700 {
  padding: 700px !important;
}

.p-n15 {
  padding: -15px !important;
}

.p-n1 {
  padding: -10px !important;
}

.p-n5 {
  padding: -5px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.6667rem !important;
}

.p-2 {
  padding: 1.3334rem !important;
}

.p-3 {
  padding: 2.0001rem !important;
}

.p-4 {
  padding: 2.6668rem !important;
}

.p-5 {
  padding: 3.3335rem !important;
}

.p-6 {
  padding: 4.0002rem !important;
}

.p-7 {
  padding: 4.6669rem !important;
}

.p-8 {
  padding: 5.3336rem !important;
}

.p-9 {
  padding: 6.0003rem !important;
}

.p-10 {
  padding: 6.667rem !important;
}

.p-gutter {
  padding: 2.133rem !important;
}

.p-out-container {
  padding: calc(50% - 50vw) !important;
}

.px-sm {
  padding-right: 0.33335rem !important;
  padding-left: 0.33335rem !important;
}

.px-3px {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.px-15px {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.px-25px {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.px-35px {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.px-700 {
  padding-right: 700px !important;
  padding-left: 700px !important;
}

.px-n15 {
  padding-right: -15px !important;
  padding-left: -15px !important;
}

.px-n1 {
  padding-right: -10px !important;
  padding-left: -10px !important;
}

.px-n5 {
  padding-right: -5px !important;
  padding-left: -5px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.6667rem !important;
  padding-left: 0.6667rem !important;
}

.px-2 {
  padding-right: 1.3334rem !important;
  padding-left: 1.3334rem !important;
}

.px-3 {
  padding-right: 2.0001rem !important;
  padding-left: 2.0001rem !important;
}

.px-4 {
  padding-right: 2.6668rem !important;
  padding-left: 2.6668rem !important;
}

.px-5 {
  padding-right: 3.3335rem !important;
  padding-left: 3.3335rem !important;
}

.px-6 {
  padding-right: 4.0002rem !important;
  padding-left: 4.0002rem !important;
}

.px-7 {
  padding-right: 4.6669rem !important;
  padding-left: 4.6669rem !important;
}

.px-8 {
  padding-right: 5.3336rem !important;
  padding-left: 5.3336rem !important;
}

.px-9 {
  padding-right: 6.0003rem !important;
  padding-left: 6.0003rem !important;
}

.px-10 {
  padding-right: 6.667rem !important;
  padding-left: 6.667rem !important;
}

.px-gutter {
  padding-right: 2.133rem !important;
  padding-left: 2.133rem !important;
}

.px-out-container {
  padding-right: calc(50% - 50vw) !important;
  padding-left: calc(50% - 50vw) !important;
}

.py-sm {
  padding-top: 0.33335rem !important;
  padding-bottom: 0.33335rem !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-35px {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py-700 {
  padding-top: 700px !important;
  padding-bottom: 700px !important;
}

.py-n15 {
  padding-top: -15px !important;
  padding-bottom: -15px !important;
}

.py-n1 {
  padding-top: -10px !important;
  padding-bottom: -10px !important;
}

.py-n5 {
  padding-top: -5px !important;
  padding-bottom: -5px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.6667rem !important;
  padding-bottom: 0.6667rem !important;
}

.py-2 {
  padding-top: 1.3334rem !important;
  padding-bottom: 1.3334rem !important;
}

.py-3 {
  padding-top: 2.0001rem !important;
  padding-bottom: 2.0001rem !important;
}

.py-4 {
  padding-top: 2.6668rem !important;
  padding-bottom: 2.6668rem !important;
}

.py-5 {
  padding-top: 3.3335rem !important;
  padding-bottom: 3.3335rem !important;
}

.py-6 {
  padding-top: 4.0002rem !important;
  padding-bottom: 4.0002rem !important;
}

.py-7 {
  padding-top: 4.6669rem !important;
  padding-bottom: 4.6669rem !important;
}

.py-8 {
  padding-top: 5.3336rem !important;
  padding-bottom: 5.3336rem !important;
}

.py-9 {
  padding-top: 6.0003rem !important;
  padding-bottom: 6.0003rem !important;
}

.py-10 {
  padding-top: 6.667rem !important;
  padding-bottom: 6.667rem !important;
}

.py-gutter {
  padding-top: 2.133rem !important;
  padding-bottom: 2.133rem !important;
}

.py-out-container {
  padding-top: calc(50% - 50vw) !important;
  padding-bottom: calc(50% - 50vw) !important;
}

.pt-sm {
  padding-top: 0.33335rem !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pt-35px {
  padding-top: 35px !important;
}

.pt-700 {
  padding-top: 700px !important;
}

.pt-n15 {
  padding-top: -15px !important;
}

.pt-n1 {
  padding-top: -10px !important;
}

.pt-n5 {
  padding-top: -5px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.6667rem !important;
}

.pt-2 {
  padding-top: 1.3334rem !important;
}

.pt-3 {
  padding-top: 2.0001rem !important;
}

.pt-4 {
  padding-top: 2.6668rem !important;
}

.pt-5 {
  padding-top: 3.3335rem !important;
}

.pt-6 {
  padding-top: 4.0002rem !important;
}

.pt-7 {
  padding-top: 4.6669rem !important;
}

.pt-8 {
  padding-top: 5.3336rem !important;
}

.pt-9 {
  padding-top: 6.0003rem !important;
}

.pt-10 {
  padding-top: 6.667rem !important;
}

.pt-gutter {
  padding-top: 2.133rem !important;
}

.pt-out-container {
  padding-top: calc(50% - 50vw) !important;
}

.pe-sm {
  padding-right: 0.33335rem !important;
}

.pe-3px {
  padding-right: 3px !important;
}

.pe-15px {
  padding-right: 15px !important;
}

.pe-25px {
  padding-right: 25px !important;
}

.pe-35px {
  padding-right: 35px !important;
}

.pe-700 {
  padding-right: 700px !important;
}

.pe-n15 {
  padding-right: -15px !important;
}

.pe-n1 {
  padding-right: -10px !important;
}

.pe-n5 {
  padding-right: -5px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.6667rem !important;
}

.pe-2 {
  padding-right: 1.3334rem !important;
}

.pe-3 {
  padding-right: 2.0001rem !important;
}

.pe-4 {
  padding-right: 2.6668rem !important;
}

.pe-5 {
  padding-right: 3.3335rem !important;
}

.pe-6 {
  padding-right: 4.0002rem !important;
}

.pe-7 {
  padding-right: 4.6669rem !important;
}

.pe-8 {
  padding-right: 5.3336rem !important;
}

.pe-9 {
  padding-right: 6.0003rem !important;
}

.pe-10 {
  padding-right: 6.667rem !important;
}

.pe-gutter {
  padding-right: 2.133rem !important;
}

.pe-out-container {
  padding-right: calc(50% - 50vw) !important;
}

.pb-sm {
  padding-bottom: 0.33335rem !important;
}

.pb-3px {
  padding-bottom: 3px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pb-35px {
  padding-bottom: 35px !important;
}

.pb-700 {
  padding-bottom: 700px !important;
}

.pb-n15 {
  padding-bottom: -15px !important;
}

.pb-n1 {
  padding-bottom: -10px !important;
}

.pb-n5 {
  padding-bottom: -5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.6667rem !important;
}

.pb-2 {
  padding-bottom: 1.3334rem !important;
}

.pb-3 {
  padding-bottom: 2.0001rem !important;
}

.pb-4 {
  padding-bottom: 2.6668rem !important;
}

.pb-5 {
  padding-bottom: 3.3335rem !important;
}

.pb-6 {
  padding-bottom: 4.0002rem !important;
}

.pb-7 {
  padding-bottom: 4.6669rem !important;
}

.pb-8 {
  padding-bottom: 5.3336rem !important;
}

.pb-9 {
  padding-bottom: 6.0003rem !important;
}

.pb-10 {
  padding-bottom: 6.667rem !important;
}

.pb-gutter {
  padding-bottom: 2.133rem !important;
}

.pb-out-container {
  padding-bottom: calc(50% - 50vw) !important;
}

.ps-sm {
  padding-left: 0.33335rem !important;
}

.ps-3px {
  padding-left: 3px !important;
}

.ps-15px {
  padding-left: 15px !important;
}

.ps-25px {
  padding-left: 25px !important;
}

.ps-35px {
  padding-left: 35px !important;
}

.ps-700 {
  padding-left: 700px !important;
}

.ps-n15 {
  padding-left: -15px !important;
}

.ps-n1 {
  padding-left: -10px !important;
}

.ps-n5 {
  padding-left: -5px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.6667rem !important;
}

.ps-2 {
  padding-left: 1.3334rem !important;
}

.ps-3 {
  padding-left: 2.0001rem !important;
}

.ps-4 {
  padding-left: 2.6668rem !important;
}

.ps-5 {
  padding-left: 3.3335rem !important;
}

.ps-6 {
  padding-left: 4.0002rem !important;
}

.ps-7 {
  padding-left: 4.6669rem !important;
}

.ps-8 {
  padding-left: 5.3336rem !important;
}

.ps-9 {
  padding-left: 6.0003rem !important;
}

.ps-10 {
  padding-left: 6.667rem !important;
}

.ps-gutter {
  padding-left: 2.133rem !important;
}

.ps-out-container {
  padding-left: calc(50% - 50vw) !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-sm {
  gap: 0.33335rem !important;
}

.gap-3px {
  gap: 3px !important;
}

.gap-15px {
  gap: 15px !important;
}

.gap-25px {
  gap: 25px !important;
}

.gap-35px {
  gap: 35px !important;
}

.gap-700 {
  gap: 700px !important;
}

.gap-n15 {
  gap: -15px !important;
}

.gap-n1 {
  gap: -10px !important;
}

.gap-n5 {
  gap: -5px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.6667rem !important;
}

.gap-2 {
  gap: 1.3334rem !important;
}

.gap-3 {
  gap: 2.0001rem !important;
}

.gap-4 {
  gap: 2.6668rem !important;
}

.gap-5 {
  gap: 3.3335rem !important;
}

.gap-6 {
  gap: 4.0002rem !important;
}

.gap-7 {
  gap: 4.6669rem !important;
}

.gap-8 {
  gap: 5.3336rem !important;
}

.gap-9 {
  gap: 6.0003rem !important;
}

.gap-10 {
  gap: 6.667rem !important;
}

.gap-gutter {
  gap: 2.133rem !important;
}

.gap-out-container {
  gap: calc(50% - 50vw) !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.o-0 {
  opacity: 0 !important;
}

.o-25 {
  opacity: 0.25 !important;
}

.o-50 {
  opacity: 0.5 !important;
}

.o-70 {
  opacity: 0.7 !important;
}

.o-75 {
  opacity: 0.75 !important;
}

.o-85 {
  opacity: 0.85 !important;
}

.o-100 {
  opacity: 1 !important;
}

.z-1 {
  z-index: 9 !important;
}

.z-2 {
  z-index: 99 !important;
}

.z-3 {
  z-index: 999 !important;
}

.z-4 {
  z-index: 1039 !important;
}

.z-5 {
  z-index: 9999 !important;
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-full {
  -o-object-fit: full !important;
     object-fit: full !important;
}

.object-position-top {
  -o-object-position: top !important;
     object-position: top !important;
}

.object-position-center {
  -o-object-position: center !important;
     object-position: center !important;
}

.object-position-bottom {
  -o-object-position: bottom !important;
     object-position: bottom !important;
}

.vh-30 {
  height: 30vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.vh-65 {
  height: 65vh !important;
}

.vh-70 {
  height: 70vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-20px {
  width: 20px !important;
}

.w-80px {
  width: 80px !important;
}

.w-180 {
  width: 180px !important;
}

.w-200px {
  width: 200px !important;
}

.w-cal200 {
  width: calc(100% - 200px) !important;
}

.mw-10 {
  max-width: 10% !important;
}

.mw-20 {
  max-width: 20% !important;
}

.mw-30 {
  max-width: 30% !important;
}

.mw-40 {
  max-width: 40% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-60 {
  max-width: 60% !important;
}

.mw-70 {
  max-width: 70% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-300 {
  max-width: 300px !important;
}

.mw-824 {
  max-width: 824px !important;
}

.mw-890 {
  max-width: 890px !important;
}

.mw-1100 {
  max-width: 1100px !important;
}

.mw-1410 {
  max-width: 1410px !important;
}

.minw-270 {
  min-width: 270px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-20px {
  height: 20px !important;
}

.h-27px {
  height: 27px !important;
}

.h-auto {
  height: auto !important;
}

.mh-490 {
  max-height: 490px !important;
}

.mh-432 {
  max-height: 432px !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.2875rem + 0.45vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fs-7 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-lg {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-md {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fs-xxs {
  font-size: 0.625rem !important;
}

.fs-15 {
  font-size: 0.9375rem !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.transform-none {
  transform: none !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-md {
  line-height: 1.65 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.order-unset {
  order: unset !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.border {
  border: 1px solid #d6d6d6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #d6d6d6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-start {
  border-left: 1px solid #d6d6d6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-end {
  border-right: 1px solid #d6d6d6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #d6d6d6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-primary {
  border-color: #f78b33 !important;
}

.border-secondary {
  border-color: #741b1d !important;
}

.border-light {
  border-color: #f5eae1 !important;
}

.border-success {
  border-color: #3ea06b !important;
}

.border-gray-100 {
  border-color: #f3f3f3 !important;
}

.border-gray-200 {
  border-color: #d0d0cf !important;
}

.border-gray-300 {
  border-color: #e2e2e2 !important;
}

.border-gray-400 {
  border-color: #808080 !important;
}

.border-gray-500 {
  border-color: #777777 !important;
}

.border-gray-700 {
  border-color: #888888 !important;
}

.border-gray-600 {
  border-color: #929292 !important;
}

.border-gray-800 {
  border-color: #666666 !important;
}

.border-gray-900 {
  border-color: #4d4d4d !important;
}

.border-body {
  border-color: #333333 !important;
}

.border-info {
  border-color: #245692 !important;
}

.border-warning {
  border-color: #ffc600 !important;
}

.border-danger {
  border-color: #c81e37 !important;
}

.border-dark {
  border-color: #cccccc !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-red {
  border-color: #c81e37 !important;
}

.border-orange {
  border-color: #ea630f !important;
}

.border-white {
  border-color: #ffffff !important;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-60 {
  top: 60% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.top-10 {
  top: 10px !important;
}

.top-20 {
  top: 20px !important;
}

.top-30 {
  top: 30px !important;
}

.top-n10 {
  top: -10px !important;
}

.top-n20 {
  top: -20px !important;
}

.top-n8 {
  top: -8px !important;
}

.top-7 {
  top: 7px !important;
}

.top-13 {
  top: 13px !important;
}

.top-15 {
  top: 15px !important;
}

.top-n3 {
  top: -3px !important;
}

.top-5 {
  top: 5px !important;
}

.top-2 {
  top: 2px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-60 {
  bottom: 60% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.bottom-n10 {
  bottom: -10px !important;
}

.bottom-n20 {
  bottom: -20px !important;
}

.bottom-n8 {
  bottom: -8px !important;
}

.bottom-7 {
  bottom: 7px !important;
}

.bottom-13 {
  bottom: 13px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.bottom-n3 {
  bottom: -3px !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.bottom-2 {
  bottom: 2px !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-60 {
  left: 60% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.start-10 {
  left: 10px !important;
}

.start-20 {
  left: 20px !important;
}

.start-30 {
  left: 30px !important;
}

.start-n10 {
  left: -10px !important;
}

.start-n20 {
  left: -20px !important;
}

.start-n8 {
  left: -8px !important;
}

.start-7 {
  left: 7px !important;
}

.start-13 {
  left: 13px !important;
}

.start-15 {
  left: 15px !important;
}

.start-n3 {
  left: -3px !important;
}

.start-5 {
  left: 5px !important;
}

.start-2 {
  left: 2px !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-60 {
  right: 60% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.end-10 {
  right: 10px !important;
}

.end-20 {
  right: 20px !important;
}

.end-30 {
  right: 30px !important;
}

.end-n10 {
  right: -10px !important;
}

.end-n20 {
  right: -20px !important;
}

.end-n8 {
  right: -8px !important;
}

.end-7 {
  right: 7px !important;
}

.end-13 {
  right: 13px !important;
}

.end-15 {
  right: 15px !important;
}

.end-n3 {
  right: -3px !important;
}

.end-5 {
  right: 5px !important;
}

.end-2 {
  right: 2px !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.rounded {
  border-radius: 0.375rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.25rem !important;
}

.rounded-2 {
  border-radius: 0.375rem !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.pevents-none {
  pointer-events: none !important;
}

.pevents-auto {
  pointer-events: auto !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #929292;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #e2e2e2;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #d0d0cf #d0d0cf #e2e2e2;
  --bs-nav-tabs-link-active-color: #888888;
  --bs-nav-tabs-link-active-bg: #ffffff;
  --bs-nav-tabs-link-active-border-color: #e2e2e2 #e2e2e2 #ffffff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #f78b33;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.33335rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: -0.15625rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.875rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.875rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #333333;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary, .footer .map .overlay_button a {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f78b33;
  --bs-btn-border-color: #f78b33;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f89c52;
  --bs-btn-hover-border-color: #f89747;
  --bs-btn-focus-shadow-rgb: 210, 118, 43;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f9a25c;
  --bs-btn-active-border-color: #f89747;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f78b33;
  --bs-btn-disabled-border-color: #f78b33;
}

.btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #741b1d;
  --bs-btn-border-color: #741b1d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #631719;
  --bs-btn-hover-border-color: #5d1617;
  --bs-btn-focus-shadow-rgb: 137, 61, 63;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5d1617;
  --bs-btn-active-border-color: #571416;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #741b1d;
  --bs-btn-disabled-border-color: #741b1d;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f5eae1;
  --bs-btn-border-color: #f5eae1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d0c7bf;
  --bs-btn-hover-border-color: #c4bbb4;
  --bs-btn-focus-shadow-rgb: 208, 199, 191;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #c4bbb4;
  --bs-btn-active-border-color: #b8b0a9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f5eae1;
  --bs-btn-disabled-border-color: #f5eae1;
}

.btn-success {
  --bs-btn-color: #000000;
  --bs-btn-bg: #3ea06b;
  --bs-btn-border-color: #3ea06b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #5bae81;
  --bs-btn-hover-border-color: #51aa7a;
  --bs-btn-focus-shadow-rgb: 53, 136, 91;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #65b389;
  --bs-btn-active-border-color: #51aa7a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #3ea06b;
  --bs-btn-disabled-border-color: #3ea06b;
}

.btn-gray-100 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f3f3f3;
  --bs-btn-border-color: #f3f3f3;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: whitesmoke;
  --bs-btn-hover-border-color: #f4f4f4;
  --bs-btn-focus-shadow-rgb: 207, 207, 207;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: whitesmoke;
  --bs-btn-active-border-color: #f4f4f4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f3f3f3;
  --bs-btn-disabled-border-color: #f3f3f3;
}

.btn-gray-200 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #d0d0cf;
  --bs-btn-border-color: #d0d0cf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d7d7d6;
  --bs-btn-hover-border-color: #d5d5d4;
  --bs-btn-focus-shadow-rgb: 177, 177, 176;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d9d9d9;
  --bs-btn-active-border-color: #d5d5d4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #d0d0cf;
  --bs-btn-disabled-border-color: #d0d0cf;
}

.btn-gray-300 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e2e2e2;
  --bs-btn-border-color: #e2e2e2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e6e6e6;
  --bs-btn-hover-border-color: #e5e5e5;
  --bs-btn-focus-shadow-rgb: 192, 192, 192;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e8e8e8;
  --bs-btn-active-border-color: #e5e5e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e2e2e2;
  --bs-btn-disabled-border-color: #e2e2e2;
}

.btn-gray-400 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #808080;
  --bs-btn-border-color: #808080;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #939393;
  --bs-btn-hover-border-color: #8d8d8d;
  --bs-btn-focus-shadow-rgb: 109, 109, 109;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #999999;
  --bs-btn-active-border-color: #8d8d8d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #808080;
  --bs-btn-disabled-border-color: #808080;
}

.btn-gray-500 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #777777;
  --bs-btn-border-color: #777777;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #8b8b8b;
  --bs-btn-hover-border-color: #858585;
  --bs-btn-focus-shadow-rgb: 101, 101, 101;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #929292;
  --bs-btn-active-border-color: #858585;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #777777;
  --bs-btn-disabled-border-color: #777777;
}

.btn-gray-700 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #888888;
  --bs-btn-border-color: #888888;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #9a9a9a;
  --bs-btn-hover-border-color: #949494;
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a0a0a0;
  --bs-btn-active-border-color: #949494;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #888888;
  --bs-btn-disabled-border-color: #888888;
}

.btn-gray-600 {
  --bs-btn-color: #000000;
  --bs-btn-bg: #929292;
  --bs-btn-border-color: #929292;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a2a2a2;
  --bs-btn-hover-border-color: #9d9d9d;
  --bs-btn-focus-shadow-rgb: 124, 124, 124;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a8a8a8;
  --bs-btn-active-border-color: #9d9d9d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #929292;
  --bs-btn-disabled-border-color: #929292;
}

.btn-gray-800 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #666666;
  --bs-btn-border-color: #666666;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #575757;
  --bs-btn-hover-border-color: #525252;
  --bs-btn-focus-shadow-rgb: 125, 125, 125;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #525252;
  --bs-btn-active-border-color: #4d4d4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #666666;
  --bs-btn-disabled-border-color: #666666;
}

.btn-gray-900 {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #4d4d4d;
  --bs-btn-border-color: #4d4d4d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #414141;
  --bs-btn-hover-border-color: #3e3e3e;
  --bs-btn-focus-shadow-rgb: 104, 104, 104;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3e3e3e;
  --bs-btn-active-border-color: #3a3a3a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #4d4d4d;
  --bs-btn-disabled-border-color: #4d4d4d;
}

.btn-body {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2b2b2b;
  --bs-btn-hover-border-color: #292929;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #292929;
  --bs-btn-active-border-color: #262626;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #333333;
  --bs-btn-disabled-border-color: #333333;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #245692;
  --bs-btn-border-color: #245692;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1f497c;
  --bs-btn-hover-border-color: #1d4575;
  --bs-btn-focus-shadow-rgb: 69, 111, 162;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1d4575;
  --bs-btn-active-border-color: #1b416e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #245692;
  --bs-btn-disabled-border-color: #245692;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffc600;
  --bs-btn-border-color: #ffc600;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffcf26;
  --bs-btn-hover-border-color: #ffcc1a;
  --bs-btn-focus-shadow-rgb: 217, 168, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffd133;
  --bs-btn-active-border-color: #ffcc1a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffc600;
  --bs-btn-disabled-border-color: #ffc600;
}

.btn-danger {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #c81e37;
  --bs-btn-border-color: #c81e37;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #aa1a2f;
  --bs-btn-hover-border-color: #a0182c;
  --bs-btn-focus-shadow-rgb: 208, 64, 85;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a0182c;
  --bs-btn-active-border-color: #961729;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #c81e37;
  --bs-btn-disabled-border-color: #c81e37;
}

.btn-dark {
  --bs-btn-color: #000000;
  --bs-btn-bg: #cccccc;
  --bs-btn-border-color: #cccccc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d4d4d4;
  --bs-btn-hover-border-color: #d1d1d1;
  --bs-btn-focus-shadow-rgb: 173, 173, 173;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d6d6d6;
  --bs-btn-active-border-color: #d1d1d1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #cccccc;
  --bs-btn-disabled-border-color: #cccccc;
}

.btn-black {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-red {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #c81e37;
  --bs-btn-border-color: #c81e37;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #aa1a2f;
  --bs-btn-hover-border-color: #a0182c;
  --bs-btn-focus-shadow-rgb: 208, 64, 85;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #a0182c;
  --bs-btn-active-border-color: #961729;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #c81e37;
  --bs-btn-disabled-border-color: #c81e37;
}

.btn-orange {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ea630f;
  --bs-btn-border-color: #ea630f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ed7a33;
  --bs-btn-hover-border-color: #ec7327;
  --bs-btn-focus-shadow-rgb: 199, 84, 13;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ee823f;
  --bs-btn-active-border-color: #ec7327;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ea630f;
  --bs-btn-disabled-border-color: #ea630f;
}

.btn-white {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-outline-primary {
  --bs-btn-color: #f78b33;
  --bs-btn-border-color: #f78b33;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f78b33;
  --bs-btn-hover-border-color: #f78b33;
  --bs-btn-focus-shadow-rgb: 247, 139, 51;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f78b33;
  --bs-btn-active-border-color: #f78b33;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f78b33;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f78b33;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #741b1d;
  --bs-btn-border-color: #741b1d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #741b1d;
  --bs-btn-hover-border-color: #741b1d;
  --bs-btn-focus-shadow-rgb: 116, 27, 29;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #741b1d;
  --bs-btn-active-border-color: #741b1d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #741b1d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #741b1d;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f5eae1;
  --bs-btn-border-color: #f5eae1;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f5eae1;
  --bs-btn-hover-border-color: #f5eae1;
  --bs-btn-focus-shadow-rgb: 245, 234, 225;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f5eae1;
  --bs-btn-active-border-color: #f5eae1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5eae1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5eae1;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #3ea06b;
  --bs-btn-border-color: #3ea06b;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3ea06b;
  --bs-btn-hover-border-color: #3ea06b;
  --bs-btn-focus-shadow-rgb: 62, 160, 107;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3ea06b;
  --bs-btn-active-border-color: #3ea06b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3ea06b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3ea06b;
  --bs-gradient: none;
}

.btn-outline-gray-100 {
  --bs-btn-color: #f3f3f3;
  --bs-btn-border-color: #f3f3f3;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f3f3f3;
  --bs-btn-hover-border-color: #f3f3f3;
  --bs-btn-focus-shadow-rgb: 243, 243, 243;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f3f3f3;
  --bs-btn-active-border-color: #f3f3f3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f3f3f3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f3f3f3;
  --bs-gradient: none;
}

.btn-outline-gray-200 {
  --bs-btn-color: #d0d0cf;
  --bs-btn-border-color: #d0d0cf;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #d0d0cf;
  --bs-btn-hover-border-color: #d0d0cf;
  --bs-btn-focus-shadow-rgb: 208, 208, 207;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #d0d0cf;
  --bs-btn-active-border-color: #d0d0cf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d0d0cf;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d0d0cf;
  --bs-gradient: none;
}

.btn-outline-gray-300 {
  --bs-btn-color: #e2e2e2;
  --bs-btn-border-color: #e2e2e2;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e2e2e2;
  --bs-btn-hover-border-color: #e2e2e2;
  --bs-btn-focus-shadow-rgb: 226, 226, 226;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e2e2e2;
  --bs-btn-active-border-color: #e2e2e2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e2e2e2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e2e2e2;
  --bs-gradient: none;
}

.btn-outline-gray-400 {
  --bs-btn-color: #808080;
  --bs-btn-border-color: #808080;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #808080;
  --bs-btn-hover-border-color: #808080;
  --bs-btn-focus-shadow-rgb: 128, 128, 128;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #808080;
  --bs-btn-active-border-color: #808080;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #808080;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #808080;
  --bs-gradient: none;
}

.btn-outline-gray-500 {
  --bs-btn-color: #777777;
  --bs-btn-border-color: #777777;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #777777;
  --bs-btn-hover-border-color: #777777;
  --bs-btn-focus-shadow-rgb: 119, 119, 119;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #777777;
  --bs-btn-active-border-color: #777777;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #777777;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #777777;
  --bs-gradient: none;
}

.btn-outline-gray-700 {
  --bs-btn-color: #888888;
  --bs-btn-border-color: #888888;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #888888;
  --bs-btn-hover-border-color: #888888;
  --bs-btn-focus-shadow-rgb: 136, 136, 136;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #888888;
  --bs-btn-active-border-color: #888888;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #888888;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #888888;
  --bs-gradient: none;
}

.btn-outline-gray-600 {
  --bs-btn-color: #929292;
  --bs-btn-border-color: #929292;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #929292;
  --bs-btn-hover-border-color: #929292;
  --bs-btn-focus-shadow-rgb: 146, 146, 146;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #929292;
  --bs-btn-active-border-color: #929292;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #929292;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #929292;
  --bs-gradient: none;
}

.btn-outline-gray-800 {
  --bs-btn-color: #666666;
  --bs-btn-border-color: #666666;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #666666;
  --bs-btn-hover-border-color: #666666;
  --bs-btn-focus-shadow-rgb: 102, 102, 102;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #666666;
  --bs-btn-active-border-color: #666666;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #666666;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #666666;
  --bs-gradient: none;
}

.btn-outline-gray-900 {
  --bs-btn-color: #4d4d4d;
  --bs-btn-border-color: #4d4d4d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #4d4d4d;
  --bs-btn-hover-border-color: #4d4d4d;
  --bs-btn-focus-shadow-rgb: 77, 77, 77;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4d4d4d;
  --bs-btn-active-border-color: #4d4d4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4d4d4d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4d4d4d;
  --bs-gradient: none;
}

.btn-outline-body {
  --bs-btn-color: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 51, 51, 51;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #333333;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #333333;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333333;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #245692;
  --bs-btn-border-color: #245692;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #245692;
  --bs-btn-hover-border-color: #245692;
  --bs-btn-focus-shadow-rgb: 36, 86, 146;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #245692;
  --bs-btn-active-border-color: #245692;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #245692;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #245692;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc600;
  --bs-btn-border-color: #ffc600;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffc600;
  --bs-btn-hover-border-color: #ffc600;
  --bs-btn-focus-shadow-rgb: 255, 198, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffc600;
  --bs-btn-active-border-color: #ffc600;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc600;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc600;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #c81e37;
  --bs-btn-border-color: #c81e37;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c81e37;
  --bs-btn-hover-border-color: #c81e37;
  --bs-btn-focus-shadow-rgb: 200, 30, 55;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c81e37;
  --bs-btn-active-border-color: #c81e37;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c81e37;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c81e37;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #cccccc;
  --bs-btn-border-color: #cccccc;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cccccc;
  --bs-btn-hover-border-color: #cccccc;
  --bs-btn-focus-shadow-rgb: 204, 204, 204;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #cccccc;
  --bs-btn-active-border-color: #cccccc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #cccccc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cccccc;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #c81e37;
  --bs-btn-border-color: #c81e37;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c81e37;
  --bs-btn-hover-border-color: #c81e37;
  --bs-btn-focus-shadow-rgb: 200, 30, 55;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c81e37;
  --bs-btn-active-border-color: #c81e37;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c81e37;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c81e37;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #ea630f;
  --bs-btn-border-color: #ea630f;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ea630f;
  --bs-btn-hover-border-color: #ea630f;
  --bs-btn-focus-shadow-rgb: 234, 99, 15;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ea630f;
  --bs-btn-active-border-color: #ea630f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ea630f;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ea630f;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #929292;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 210, 118, 43;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.3125rem + 0.75vw);
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 0.6667rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 0.6667rem;
  --bs-modal-header-padding-y: 0.6667rem;
  --bs-modal-header-padding: 0.6667rem 0.6667rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #245692;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23245692'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #245692;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(247, 139, 51, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.3125rem + 0.75vw);
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #929292;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #808080;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #333333;
  background-color: #ffffff;
  border-color: #fbc599;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(247, 139, 51, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #929292;
  opacity: 1;
}
.form-control::placeholder {
  color: #929292;
  opacity: 1;
}
.form-control:disabled {
  background-color: #d0d0cf;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #333333;
  background-color: #d0d0cf;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #c6c6c5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #333333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #808080;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #fbc599;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(247, 139, 51, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #d0d0cf;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #333333;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #fbc599;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(247, 139, 51, 0.25);
}
.form-check-input:checked {
  background-color: #245692;
  border-color: #245692;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #f78b33;
  border-color: #f78b33;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fbc599'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(247, 139, 51, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(247, 139, 51, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #f78b33;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fddcc2;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e2e2;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #f78b33;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fddcc2;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e2e2;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #777777;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #777777;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  background-color: #d0d0cf;
  border: 1px solid #808080;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.3125rem + 0.75vw);
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #3ea06b;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000000;
  background-color: rgba(62, 160, 107, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3ea06b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ea06b' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3ea06b;
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #3ea06b;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ea06b' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #3ea06b;
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #3ea06b;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #3ea06b;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(62, 160, 107, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3ea06b;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #c81e37;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.166675rem 0.33335rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(200, 30, 55, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #c81e37;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23c81e37'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c81e37' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #c81e37;
  box-shadow: 0 0 0 0.25rem rgba(200, 30, 55, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #c81e37;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23c81e37'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c81e37' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #c81e37;
  box-shadow: 0 0 0 0.25rem rgba(200, 30, 55, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #c81e37;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #c81e37;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(200, 30, 55, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #c81e37;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.accordion {
  --bs-accordion-color: #333333;
  --bs-accordion-bg: #ffffff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #333333;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333333'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23de7d2e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #fbc599;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(247, 139, 51, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #de7d2e;
  --bs-accordion-active-bg: #fef3eb;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

header .head_widget {
  transition: 0.2s;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .container.pt-lg-2 {
  transition: 0.2s;
}
header.affix {
  background-color: rgba(255, 255, 255, 0.95) !important;
  box-shadow: 0px 1px 5px #333333;
}
header.affix .head_widget {
  height: 0;
  overflow: hidden;
  padding: 0 !important;
}
header.affix .container.pt-lg-2 {
  padding-top: 5px !important;
}
header.affix .main_menu #menu-main-menu > li > a {
  padding: 10px 0;
}
header.affix .submenu_hover {
  top: 42px;
}
header.affix #total-items {
  top: -16px;
}

header {
  background-color: white;
}
header .main_menu #menu-home a {
  color: #aaa;
  line-height: 1;
  font-size: 13px;
}

.header_left .icon-link:hover a,
.header_right .icon-link:hover a {
  text-shadow: 0 0 2px #ffffff;
  font-size: 11px;
}

.main_menu {
  position: relative;
  min-height: 20px;
}
.main_menu #menu-home .menu-item {
  padding: 0 15px;
}
.main_menu #menu-home .sub-menu {
  display: none;
}
.main_menu #menu-home .current-menu-item a {
  color: #741b1d !important;
}
.main_menu #menu-home a {
  color: #888888;
  line-height: 1;
  font-size: 13px;
}
.main_menu #menu-home a:hover {
  color: #741b1d;
}

.search_toggle {
  top: 50px;
}
.search_toggle form {
  max-width: 234px;
}
.search_toggle input[type=search] {
  width: 100%;
  background: transparent;
  padding: 4px 30px 6px 20px;
  height: 35px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  transition: all 0.3s ease-in-out;
}
.search_toggle input[type=submit] {
  width: 35px;
  height: 35px;
  background: transparent;
  border: none;
  font-size: 0;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
}

.header_left,
.header_right {
  height: 70px;
}
.header_left .icon-link,
.header_right .icon-link {
  width: 28px;
  height: 28px;
  border: 1px solid transparent;
  margin: 0 10px;
  transition: 0.2s;
}
.header_left .icon-link img,
.header_right .icon-link img {
  width: 26px;
  height: 26px;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.8;
  display: block;
  margin-bottom: 5px;
  opacity: 0.5;
}
.header_left .icon-link img:hover,
.header_right .icon-link img:hover {
  opacity: 1;
}
.header_left .icon-link:hover,
.header_right .icon-link:hover {
  border: 1px solid #cccccc;
}
.header_left .icon-link:hover a,
.header_right .icon-link:hover a {
  color: black;
}
.header_left .icon-link a,
.header_right .icon-link a {
  color: transparent;
  display: block;
}

i::before {
  content: none !important;
}

@media (max-width: 767.98px) {
  .bar-wrapper {
    display: block;
    position: relative;
    background: transparent;
    z-index: 9999;
    width: 22px;
    background-image: none;
    padding: 9px 10px;
    border: 1px solid transparent;
  }
  .bar-wrapper span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000000;
    opacity: 1;
    left: 0;
    transition: transform 0.2s linear, top 0.3s linear, width 0.3s ease-in-out, opacity 0.5s ease-in-out;
  }
  .bar-wrapper span:first-child {
    top: 0;
  }
  .bar-wrapper span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  .bar-wrapper span:nth-child(3) {
    bottom: 0;
  }
  body.menu-open header .bar-wrapper span:first-child {
    transform: rotate(45deg);
    top: 50%;
    width: 100%;
  }
  body.menu-open header .bar-wrapper span:nth-child(2) {
    width: 0;
    opacity: 0;
  }
  body.menu-open header .bar-wrapper span:last-child {
    transform: rotate(-45deg);
    bottom: unset;
    top: 50%;
  }
  .main_menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
  }
  .main_menu a {
    display: block;
    border-bottom: 1px solid #555;
    padding: 10px;
  }
  .menu-main-menu-container {
    max-height: 0;
    overflow-y: auto;
    position: fixed;
    top: 58px;
    left: 0;
    background: #ffffff;
    height: calc(100vh - 110px);
    transition: max-height 0.2s ease-out;
    width: 100%;
    z-index: 99;
  }
  .menu-main-menu-container #menu-main-menu {
    padding: 20px;
    padding-bottom: 5px;
  }
  .menu-main-menu-container #menu-main-menu > li > a {
    padding: 10px;
  }
  ul {
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }
  ul li {
    position: relative;
  }
  ul li i {
    z-index: 10;
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  ul li i svg {
    pointer-events: none;
  }
  .submenu_hover,
  .sub-menu {
    max-height: 0;
    position: relative;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
  }
  .submenu_hover ul,
  .sub-menu ul {
    padding: 0 0 0 15px;
  }
  .header_right .search_toggle {
    width: 100%;
    max-width: 100%;
  }
  .header_right .search_toggle > form {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .header_right .search_toggle > div {
    flex: 0 0 50px;
    max-width: 50px;
  }
}
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

#mobileSearch {
  display: none;
}

#mobileNavi {
  display: none;
}

@media (max-width: 991.98px) {
  header {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .main_menu #menu-main-menu > li > a {
    font-size: 15px;
  }
  #menu-home {
    flex-direction: column;
  }
  .custom-logo-link img {
    max-height: 35px;
  }
  #icon_quote img {
    width: 30px;
  }
  .header_left,
  .header_right {
    display: none !important;
  }
  #mobileNavi {
    display: block;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(/src/images/hamburger-menu-mobile-svgrepo-com.svg);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 15px;
    text-indent: -333px;
    overflow: hidden;
    display: block;
    cursor: pointer;
    z-index: 300;
    opacity: 0.5;
    opacity: 0.8;
    transition: all 0.5s ease;
    filter: invert(1) grayscale(100%) brightness(200%);
  }
  #mobileSearch {
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
    filter: invert(1) grayscale(100%) brightness(200%);
  }
  #mobileSearch.icon-link img {
    width: 28px;
    height: 28px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  #mobileNavi.navi-open {
    background-image: url(/src/images/close-x-svgrepo-com.svg);
    filter: invert(1) grayscale(100%) brightness(200%);
  }
  .main_menu {
    left: -100%;
    top: 60px;
    transition: all 0.4s;
    height: 100vh;
  }
  .main_menu a {
    color: black !important;
  }
  .main_menu.open {
    left: 0;
  }
  .main_menu.open.small {
    display: block;
    top: 60px;
    transition: all 0.4s;
  }
  header {
    height: 60px !important;
    transition: all 0.3s;
  }
  header .header_logo {
    padding-top: 10px;
  }
  header.small {
    height: 60px !important;
    transition: all 0.3s;
  }
  .icon-link {
    width: 50px;
    height: auto;
  }
  .icon-link a {
    color: #666;
  }
  .main_menu a {
    display: block;
    border-bottom: 1px solid #555;
    padding: 10px;
    font-size: 12px;
  }
  .search_toggle {
    top: 70px;
    width: 100%;
    left: 104px;
  }
  .navbar-header .icon-link {
    width: auto;
    height: auto;
  }
  .navbar-header .icon-link img {
    height: 30px;
    height: 30px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media (max-width: 767.98px) {
  .main_menu {
    min-height: unset;
  }
  .main_menu #menu-main-menu > li > a {
    font-size: 1rem;
  }
  .head_widget {
    font-size: 13px;
  }
  #close_button img {
    width: 12px;
  }
  .custom-logo-link img {
    max-height: 40px;
    width: auto;
  }
  .header_right {
    padding-top: 8px;
  }
  #quote-li {
    position: absolute;
    right: 15px;
    top: 2px;
  }
  #quote-li img.icon {
    width: 30px;
  }
  #quote-li #total-items {
    font-size: 10px;
    top: -13px;
    width: 15px;
    height: 15px;
  }
  div#n2-ss-3 .n2-ss-control-bullet {
    bottom: 115px !important;
  }
  header.affix #quote-li #total-items {
    top: -10px;
  }
  header.affix .submenu_hover {
    top: 0;
  }
}
@media (max-width: 575.98px) {
  .custom-logo-link img {
    max-height: 35px;
  }
  #icon_quote img {
    width: 30px;
  }
  .header_left,
  .header_right {
    display: none !important;
  }
  #mobileNavi {
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(/src/images/hamburger-menu-mobile-svgrepo-com.svg);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 15px;
    text-indent: -333px;
    overflow: hidden;
    display: block;
    cursor: pointer;
    z-index: 300;
    opacity: 0.5;
    opacity: 0.8;
  }
  #mobileNavi.navi-open {
    background-image: url(/src/images/close-x-svgrepo-com.svg);
  }
  .main_menu.open {
    top: 60px;
    transition: all 0.4s ease-in-out;
  }
}
.footer {
  font-size: 14px;
  color: #929292;
}
.footer a {
  color: #929292;
}
.footer h4 {
  font-size: 14px;
  font-family: "SairaCondensed-Regular", sans-serif;
  font-weight: bold;
  margin-bottom: 23px;
  letter-spacing: 0;
  color: #929292;
}
.footer_logo figure {
  text-align: left;
}
.footer_logo ul {
  margin-bottom: 0;
}
.footer_logo ul li {
  padding-right: 20px;
  padding-bottom: 5px;
}
.footer_logo ul li img {
  max-height: 32px;
  width: auto !important;
}
.footer .map {
  height: 500px;
}
.footer .map .map_widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  opacity: 1;
  transition: 0.5s opacity ease-in-out;
}
.footer .map .map_widget.show_map {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s opacity ease-in-out;
}
.footer .map .overlay_title,
.footer .map .overlay_button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.footer .map .overlay_title {
  font-weight: 600;
  letter-spacing: 2px;
  top: 35%;
  font-size: 26px;
}
.footer .map .overlay_button {
  justify-content: center;
}
.footer .map .overlay_button a {
  width: 350px;
  padding: 15px 0 !important;
}
.footer .map .widget_media_image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.footer .map .widget_media_image div {
  height: 100%;
  width: 100%;
}
.footer .map .widget_media_image figure {
  margin: 0;
  height: 100%;
  width: 100%;
}
.footer .map .widget_media_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1199.98px) {
  .footer .map .overlay_title {
    font-size: 22px;
  }
  .footer .map .overlay_button a {
    width: 300px;
    padding: 10px 0 !important;
  }
}

@media (max-width: 767.98px) {
  .footer h4 {
    margin-bottom: 12px;
  }
}
.post-footer {
  z-index: 10;
  display: block;
  width: 100%;
  background-color: white;
  transition: all 0.5s ease;
}
.post-footer:hover {
  background: rgb(255, 255, 255);
}
.post-footer:hover .layout_short a {
  color: #741b1d;
}
.post-footer:hover .layout_short a span {
  filter: none;
}
.post-footer:hover #copyright li,
.post-footer:hover #copyright .copyright-text {
  border-right: 1px solid #888888;
}
.post-footer:hover #copyright li a,
.post-footer:hover #copyright .copyright-text a {
  color: #888888;
  filter: none;
}
.post-footer:hover #copyright li a:hover a,
.post-footer:hover #copyright .copyright-text a:hover a {
  color: #741b1d;
}

#copyright {
  padding-bottom: 12px;
}
#copyright ul {
  margin-bottom: 0;
}
#copyright li,
#copyright .copyright-text {
  font-size: 11px;
  transition: all 0.5s ease;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #888888;
}
#copyright li:hover a,
#copyright .copyright-text:hover a {
  color: #741b1d;
}
#copyright li {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
}
#copyright li a {
  color: #888888;
}
#copyright li a:hover {
  color: #741b1d;
}
#copyright .copyright-text {
  line-height: 17px;
}

.inside {
  width: 960px;
  background: none;
  position: relative;
  margin: 0 auto;
}

.home .mod_newslist {
  display: block;
  padding-top: 10px;
  padding-bottom: 5px;
}

.mod_newslist {
  display: none;
}

.layout_short {
  margin: 5px 0 0 0;
  border-bottom: none;
}

.block {
  overflow: hidden;
}

.layout_short p.info {
  float: left;
  font-size: 13px;
  padding: 0;
  line-height: 1.1;
  color: black;
  margin-bottom: 10px;
}

.layout_short h2 {
  margin: 0;
  margin-left: 90px;
  font-size: 14px;
  padding: 0;
  line-height: 1.1;
}
.layout_short h2 span {
  filter: drop-shadow(2px 4px 6px black);
}

.layout_short a {
  color: #fff;
}
.layout_short a:hover {
  color: red;
}

@media (max-width: 991.98px) {
  .post-footer {
    height: auto;
    margin-top: 0;
    background-color: #f2f2f2;
  }
  .inside {
    width: 100%;
    height: auto;
  }
  .layout_short a {
    color: #666;
  }
  .layout_short h2 span {
    filter: none;
  }
  #copyright {
    position: unset;
    padding-bottom: 0;
  }
  #copyright ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  #copyright li {
    padding-right: 10px;
    margin-right: 10px;
    border-right: none;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    border-bottom: 1 solid #666;
    padding: 5px 0;
    text-align: center;
  }
  #copyright li a {
    filter: none;
    color: #999;
  }
  #copyright li a:hover {
    color: #741b1d;
  }
  #copyright .copyright-text,
  #copyright ul {
    width: 100%;
  }
  #copyright .copyright-text {
    order: 2;
    margin-right: 0;
    padding-right: 0;
    text-align: center;
    position: relative;
    border: 0;
    padding: 5px 0;
    z-index: 1;
  }
  #copyright .copyright-text::after {
    content: "";
    position: absolute;
    left: calc(var(--bs-gutter-x) * -0.5);
    top: 0;
    width: calc(100% + var(--bs-gutter-x));
    height: 100%;
    background-color: #999;
    z-index: -1;
  }
  .mod_newslist .layout_short {
    padding-bottom: 10px;
    border-bottom: 1px solid #999;
  }
  .mod_newslist p.info {
    float: none;
    margin-bottom: 5px;
  }
  .mod_newslist h2 {
    margin-left: 0;
  }
}
/**
 * @package     Joomla.Site
 * @subpackage  Templates.noName
 *
 * @copyright   Copyright (C) 2020 noName. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
#total-items-count,
#total-items {
  position: absolute;
  top: -20px;
  right: -8px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  background: #000000;
  border-radius: 50%;
  color: #ffffff;
  padding-top: 1px;
}

.mini-quote-dropdown {
  position: fixed;
  height: 100vh;
  width: 560px;
  max-width: 100%;
  right: -190vw;
  top: 0;
  z-index: 99999;
  box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  transition: 0.3s;
}
.mini-quote-dropdown:before {
  position: fixed;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition: 0.3s;
  display: none;
}
.mini-quote-dropdown.show {
  right: 0;
}
.mini-quote-dropdown.show:before {
  display: block;
}
.mini-quote-dropdown.show .cart_module {
  position: relative;
  z-index: 999;
  height: 100%;
  background: #ffffff;
}
.mini-quote-dropdown ul li .img {
  width: 80px;
}
.mini-quote-dropdown ul li .img img {
  margin: 0 !important;
  width: 100%;
  height: inherit;
  float: none;
}
.mini-quote-dropdown ul li .text_ct {
  width: calc(100% - 80px);
  padding-left: 15px;
}
.mini-quote-dropdown ul li .tit {
  width: calc(100% - 160px);
}
.mini-quote-dropdown ul li .price {
  width: 160px;
}
.mini-quote-dropdown ul li .price img {
  width: 20px;
}

/**
 * @package     Joomla.Site
 * @subpackage  Templates.noName
 *
 * @copyright   Copyright (C) 2020 noName. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * all-round-gothic:
 *   - http://typekit.com/eulas/00000000000000003b9b3766
 *   - http://typekit.com/eulas/00000000000000003b9b3767
 *   - http://typekit.com/eulas/00000000000000003b9b376c
 *   - http://typekit.com/eulas/00000000000000003b9b376d
 * raleway:
 *   - http://typekit.com/eulas/00000000000000003b9b13b9
 *   - http://typekit.com/eulas/00000000000000003b9b13b3
 *   - http://typekit.com/eulas/00000000000000003b9b13b2
 *   - http://typekit.com/eulas/00000000000000003b9b13b8
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-10-23 05:50:06 UTC"}*/
@font-face {
  font-family: "SairaCondensed-Regular";
  src: local("SairaCondensed-Regular"), local("SairaCondensed-Regular"), url("../fonts/SairaCondensed-Regular.woff2") format("woff2"), url("../fonts/SairaCondensed-Regular.woff") format("woff"), url("../fonts/SairaCondensed-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}
@font-face {
  font-family: "SairaCondensed-Medium";
  src: url("../fonts/SairaCondensed-Medium.woff2") format("woff2"), url("../fonts/SairaCondensed-Medium.woff") format("woff"), url("../fonts/SairaCondensed-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SairaCondensed-SemiBold";
  src: url("../fonts/SairaCondensed-SemiBold.woff2") format("woff2"), url("../fonts/SairaCondensed-SemiBold.woff") format("woff"), url("../fonts/SairaCondensed-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SairaCondensed-Bold";
  src: url("../fonts/SairaCondensed-Bold.woff2") format("woff2"), url("../fonts/SairaCondensed-Bold.woff") format("woff"), url("../fonts/SairaCondensed-Bold.ttf") format("truetype");
  font-display: swap;
}
* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  min-height: 100%;
  position: relative;
  margin-bottom: 0;
  overflow-x: hidden;
}
body header {
  height: 115px;
}
body header .header_logo {
  width: 240px;
  height: 50px;
  margin-top: 12px;
  transition: all ease 0.5s;
}
body header .header_logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

main {
  min-height: 86vh;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: 0.3s;
  color: #000000;
}
a:hover, a:focus {
  color: #4d4d4d;
}

.ls-1 {
  letter-spacing: 1px;
}

figcaption {
  text-align: center;
}

blockquote,
em {
  font-family: var(--bs-font-sans-serif-medium);
}

ul {
  padding: 0;
  list-style: none;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: var(--bs-font-sans-serif-bold);
  color: #333333;
}
h6 a,
.h6 a,
h5 a,
.h5 a,
h4 a,
.h4 a,
h3 a,
.h3 a,
h2 a,
.h2 a,
h1 a,
.h1 a {
  color: #333333;
  transition: 0.3s;
}
h6 a:hover,
.h6 a:hover,
h5 a:hover,
.h5 a:hover,
h4 a:hover,
.h4 a:hover,
h3 a:hover,
.h3 a:hover,
h2 a:hover,
.h2 a:hover,
h1 a:hover,
.h1 a:hover {
  color: #333333;
}

video:focus {
  outline: none;
}

.pager-list .st-pagelink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pager-list .st-pagelink span,
.pager-list .st-pagelink a {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #f78b33;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--bs-font-sans-serif-bold);
}
.pager-list .st-pagelink span {
  background: #f78b33;
  color: #ffffff;
}
.pager-list .st-pagelink a {
  color: #f78b33;
}
.pager-list .st-pagelink img {
  width: 20px;
}

.btn-primary, .footer .map .overlay_button a {
  color: #ffffff;
  border: 1px solid #000000;
  display: inline-block;
  text-align: center;
  padding: 8px 10px 9px;
  border-radius: 0;
  transition: 0.3s;
  min-width: 200px;
  background-color: #000000;
  font-size: 18px;
}
.btn-primary:hover, .footer .map .overlay_button a:hover, .btn-primary:active, .footer .map .overlay_button a:active {
  background-color: #ffffff;
  color: #000000;
  text-decoration: none;
  border: 1px solid #000000;
  box-shadow: none;
}
.btn-primary a, .footer .map .overlay_button a a {
  color: #ffffff;
}
.btn-primary:hover a, .footer .map .overlay_button a:hover a, .btn-primary:active a, .footer .map .overlay_button a:active a {
  color: #000000;
}

input:focus {
  outline: none;
}

.swiper-pagination {
  width: 100%;
  margin: 0;
  bottom: 25px !important;
  left: 95%;
}
.swiper-pagination .swiper-pagination-bullet {
  background-color: #ffffff;
  opacity: 0.5;
  margin: 0 3px;
  padding: 10px;
}
.swiper-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-button-prev,
.swiper-button-next {
  height: 80px !important;
  width: 20px !important;
  background: rgba(36, 30, 32, 0.3);
  transform: translateY(-50%);
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px !important;
  color: #ffffff;
}

.swiper-button-prev {
  left: 0 !important;
}
.swiper-button-prev:focus {
  outline: none;
}

.swiper-button-next {
  right: 0 !important;
}
.swiper-button-next:focus {
  outline: none;
}

.overlay {
  position: relative;
}
.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel] {
  width: 100%;
  font-size: 15px;
  padding: 10px 15px;
  color: #333333;
  border: 1px solid #cccccc;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: none;
  box-shadow: none;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=tel]:focus {
  outline: none;
}

textarea {
  height: 80px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  height: 108px;
  padding: 10px 15px;
  font-size: 15px;
  color: #333333;
  resize: none;
}
textarea:focus {
  outline: none;
}

input[type=radio] + span:before {
  border-radius: 50%;
}
input[type=radio]:checked + span:after {
  top: 10px;
}

select {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--bs-primary);
  background: var(--bs-white);
  color: var(--bs-body-color);
  padding: 0 15px 0 10px;
  margin-right: 0 !important;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: none;
}
select:focus-visible {
  outline: none;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  margin: 0 !important;
}

.show-password-input {
  display: none;
}

.woocommerce-breadcrumb a {
  font-family: "SairaCondensed-Regular", sans-serif;
  color: #000000 !important;
}

.product_img img {
  max-height: 180px;
}
.product_img img.on {
  display: none;
}
.product_img a:hover img.off {
  display: none;
}
.product_img a:hover img.on {
  display: block;
}

.scroll_style {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll_style ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: #fff;
}
.scroll_style ::-webkit-scrollbar {
  width: 7px;
  background-color: #f3f3f3;
}
.scroll_style ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px transparent;
  background-color: #d0d0cf;
  box-shadow: inset 0 0 6px transparent;
}

#swiper-slider-blog .swiper-button-prev {
  left: -35px !important;
}
#swiper-slider-blog .swiper-button-next {
  right: -35px !important;
}
#swiper-slider-blog .swiper-slide img {
  min-height: 289px;
}
#swiper-slider-blog .swiper-button-prev,
#swiper-slider-blog .swiper-button-next {
  background-color: transparent !important;
  height: 40px !important;
  width: 10px !important;
  font-weight: bold;
}
#swiper-slider-blog .swiper-button-prev:after,
#swiper-slider-blog .swiper-button-next:after {
  color: #777777;
}

.main_banner img {
  min-height: 425px;
}

.list_post_main figure {
  height: 420px;
}

.afrfqbt_single_page,
.green_btn {
  display: inline-block;
  color: #ffffff;
  background: #3ea06b !important;
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 9px 10px 11px;
  border: 1px solid #3ea06b !important;
  transition: 0.3s;
  margin-bottom: 5px;
}
.afrfqbt_single_page:hover, .afrfqbt_single_page:focus,
.green_btn:hover,
.green_btn:focus {
  text-decoration: none;
  opacity: 0.8;
  outline: none;
  color: #ffffff;
}

.text-hover-none:hover {
  text-decoration: none !important;
}

.lmp_products_loading {
  display: none !important;
}

.woocommerce-breadcrumb {
  margin-bottom: 0 !important;
}

.pagination_main {
  position: relative;
}
.pagination_main .btn-primary, .pagination_main .footer .map .overlay_button a, .footer .map .overlay_button .pagination_main a {
  min-width: 168px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.mx-70 {
  max-width: 70%;
}

@media (max-width: 767.98px) {
  .container {
    max-width: 100% !important;
  }
}

.page404 {
  background: url("root/images/404.jpg") left top peat;
  background-size: cover;
  background-position: center;
  padding: 230px 0 !important;
}
.page404 .container {
  max-width: 950px;
}

@media (max-width: 1199.98px) {
  #swiper-slider-blog .swiper-slide img {
    min-height: 227px;
  }
}
@media (max-width: 991.98px) {
  header {
    height: 60px !important;
    transition: all 0.3s;
  }
  body header .header_logo {
    padding-top: 10px;
    margin-top: 0;
    height: 60px;
  }
  header.small {
    height: 60px !important;
    transition: all 0.3s;
  }
  .wp-block-media-text {
    grid-template-columns: 100% !important;
  }
  .wp-block-media-text .wp-block-media-text__content {
    grid-column: 1 !important;
    grid-row: 2 !important;
  }
  .wp-block-media-text .wp-block-media-text__media {
    grid-column: 1 !important;
    grid-row: 1 !important;
  }
  .pagination_main .btn-primary, .pagination_main .footer .map .overlay_button a, .footer .map .overlay_button .pagination_main a {
    position: static;
    display: block;
    margin: 0 auto;
    transform: inherit;
  }
  .mx-70 {
    max-width: 100%;
  }
  .introFadeInLeft,
  .introFadeInRight,
  .introFadeInUp,
  .introFadeInDown {
    transition: none !important;
    opacity: 1 !important;
    transform: none !important;
  }
  .swiper-wrapper {
    flex-wrap: wrap;
  }
  body main {
    height: auto;
  }
  .fields-of-operation h2 {
    font-size: 28px !important;
  }
  .design_build_contractor-list img {
    width: 35px;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .img-bg-2 {
    top: 21%;
    left: 20%;
    right: 0;
  }
  .general-contractor-lists {
    max-width: 170px;
  }
  .general-contractor-lists img {
    width: 30px;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .slick-arrow.arrow span {
    padding: 3px 15px !important;
  }
  .slick-arrow.arrow.prev span {
    padding: 3px 15px !important;
  }
  .btn-arrow {
    bottom: 0;
    right: 0;
  }
  .safety_culture .slick-dots {
    top: 130px !important;
  }
}
@media (max-width: 767.98px) {
  #swiper-slider-blog .swiper-button-prev {
    left: -10px !important;
  }
  #swiper-slider-blog .swiper-button-next {
    right: -10px !important;
  }
}
@media (max-width: 575.98px) {
  header {
    height: 60px !important;
    transition: all 0.3s;
  }
  header .header_logo {
    padding-top: 10px;
  }
  header.small {
    height: 60px !important;
    transition: all 0.3s;
  }
  .head_widget {
    font-size: 11px;
  }
  .mini-quote-dropdown ul li .tit {
    width: 100%;
  }
  .mini-quote-dropdown ul li .cate,
  .mini-quote-dropdown ul li .price {
    font-size: 14px;
  }
  .mini-quote-dropdown ul li .price {
    width: 100%;
    text-align: left !important;
  }
  .mini-quote-dropdown ul li .text_ct {
    position: relative;
    padding-right: 10px;
  }
  .mini-quote-dropdown ul li .cart_dtl {
    position: absolute;
    right: -10px;
    top: 20px;
  }
}
.list {
  list-style: initial !important;
  padding-left: 2rem;
}
.list li {
  margin-bottom: 1rem;
}

.contact_block img {
  width: 100%;
  height: 420px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 991.98px) {
  .contact_title {
    font-size: 40px !important;
  }
  .contact_block {
    flex-wrap: wrap !important;
  }
  .contact_block .wp-block-column {
    flex-basis: unset !important;
  }
  .contact_block img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 991.98px) {
  .main__page--title {
    font-size: 40px !important;
  }
}

.sub_menu_project .menu-item {
  padding: 0 15px;
}

.project-thumbnail-post {
  width: 150px;
  height: 100px;
}

.thumb_view li {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 100px;
  padding: 0;
  margin: 2px;
  color: #333;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.1;
  background: #ccc;
  overflow: hidden;
  vertical-align: top;
}
.thumb_view li a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  text-decoration: none;
  z-index: 100;
  background: rgba(255, 255, 255, 0.1);
  color: transparent;
  padding: 25px 5px 0 5px;
}
.thumb_view li a:hover {
  color: #000;
  background: rgba(255, 255, 255, 0.8);
}

.shadow-text-white {
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4) 400px, transparent 800px);
  background-size: 800px 100%;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  filter: drop-shadow(2px 4px 6px black);
}

.career {
  overflow: hidden;
}

#career-slider .swiper-pagination-bullet,
#fields-of-operation-slider .swiper-pagination-bullet {
  background: none;
  font-size: 20px;
  font-weight: 500;
  color: #888888;
  margin: 0 0 30px;
  transition: opacity 0.2s ease-in;
  display: flex;
  justify-content: flex-end;
  width: 50px;
}
#career-slider .swiper-pagination-bullet.swiper-pagination-bullet-active,
#fields-of-operation-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #741b1d;
  text-shadow: 0 0 2px #ffffff;
  font-size: 32px;
}

.fields-of-operation {
  overflow: hidden;
}
.fields-of-operation .img-general_contractor {
  height: 750px;
  -o-object-fit: contain;
     object-fit: contain;
}
.fields-of-operation .link a {
  display: inline-flex;
  background-color: white;
  font-size: 14px;
  line-height: 52px;
  text-transform: uppercase;
  padding: 0;
  border: unset;
  font-weight: 700;
  color: #245692;
  font-size: 12px;
  line-height: 46px;
}
.fields-of-operation .link .text {
  display: block;
  padding-left: 28px;
  padding-right: 21px;
}
.fields-of-operation .link .arrow-button {
  border-left: 1px solid rgba(36, 86, 146, 0.15);
  display: block;
  width: 47px;
  height: 46px;
  background: url(../images/right-arrow-button.png) center no-repeat;
  background-size: 20px;
  filter: brightness(1) invert(0);
}
.fields-of-operation h2 {
  font-size: 43px;
}

.site-main-new {
  padding-top: 12%;
}
.site-main-new .title {
  font-weight: 100 !important;
}

.safety-culture-slider img {
  width: 500px;
  height: 315px;
  -o-object-fit: cover;
     object-fit: cover;
}

.equipment_capacity .row img {
  width: 500px;
  height: 315px;
  -o-object-fit: cover;
     object-fit: cover;
}

.introFadeInLeft {
  transition: all 1.4s ease;
  opacity: 0;
  transform: translate3d(-20%, 0, 0);
}

.introFadeInRight {
  transition: all 1.4s ease;
  opacity: 0;
  transform: translate3d(20%, 0, 0);
}

.introFadeInUp {
  transition: all 1.4s ease;
  opacity: 0;
  transform: translate3d(0, 20%, 0);
}

.introFadeInDown {
  transition: all 1.4s ease;
  opacity: 0;
  transform: translate3d(0, -20%, 0);
}

.swiper-slide-active .introFadeInRight,
.swiper-slide-active .introFadeInLeft,
.swiper-slide-active .introFadeInUp,
.swiper-slide-active .introFadeInDown,
.contact_main .introFadeInRight,
.contact_main .introFadeInLeft,
.contact_main .introFadeInUp,
.contact_main .introFadeInDown {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.contact_main {
  overflow: hidden;
}